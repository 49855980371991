.body.step {
  display: flex;
  flex-direction: column;
}
/* ------------------------------------------ */
.step-directions {
  margin-bottom: calc(var(--space-one) * 2);
  display: flex;
  flex-direction: column;
  font-size: var(--lg);
}
.step-item:nth-child(1) {
  margin: 0 auto var(--space-one);
  font-weight: bold;
  font-family: var(--code-font);
}
/* ------------------------------------------ */
.step-recorder {
  display: flex;
  justify-content: center;
  margin-bottom: calc(1.4 * var(--space-one));
}
.recorder-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  --ht: calc(var(--btn-h) * 1.2);
  width: var(--ht);
  height: var(--ht);
  border-radius: calc(var(--ht) * 0.5);
  border: 2px solid var(--dark);
  cursor: pointer;
}
/* ------------------------------------------ */
.record-thumb {
  display: flex;
  align-items: center;
}
.audio-player {
  width: calc(100% - 60px);
  width: 100%;
}
.audio-player:focus {
  outline: none;
}
.audio-player::-webkit-media-controls-panel {
  background-color: var(--light);
}
.record-thumb-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
}
.no-records {
  font-size: var(--sm);
  margin: auto;
}