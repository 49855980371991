@import url(https://fonts.googleapis.com/css2?family=Lekton:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,300;0,400;0,600;1,300;1,600&display=swap);
.header {
  display: flex;
  align-items: center;
  height: var(--hdr-h);
  padding: 0 var(--space-one);
}
.header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
}
.header-btn.left {
  margin-right: auto;
}
.header-btn.right {
  margin-left: auto;
}
.header-btn.disabled {
  pointer-events: none;
}
.header-title {
  margin: auto;
  font-size: var(--md);
  font-weight: bold;
  font-family: var(--code-font);
}
/* --------------- DriftThumb ---------------- */
.drift-thumb {
  display: flex;
  align-items: center;
  background-color: var(--light);
  padding: calc(var(--space-one) * 0.5) var(--space-one);
  margin-bottom: calc(var(--space-one) * 1.2);
  border: 2px solid var(--dark);
  box-shadow: 4px 4px 0 var(--dark);
  cursor: pointer;
  background: whitesmoke;
}
.drift-thumb:last-child {
  margin-bottom: 6px;
}
.drift-thumb-list li {
  list-style: none;
  font-size: var(--md);
  line-height: 1.8em;
}
.drift-thumb-list span{
  color: crimson;
}
.drift-thumb-arrow {
  display: flex;
  align-items: center;
  margin-left: auto;
}
/* ------------------------------------------ */
.drift-empty {
  font-size: var(--md);
  position: relative;
  /* width: 100%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 2.4em;
}
/* ------------------------------------------ */
.audiowalk {
  background-color: rgb(214, 90, 0);
  cursor: pointer;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.1em;
	box-shadow: 0 0 0 0 rgba(214, 90, 0, 1);
	transform: scale(1);
	-webkit-animation: pulse-black 2s infinite;
	        animation: pulse-black 2s infinite;
}
.audiowalk p:last-child {
  font-size: 0.8em;
  margin-top: 8px;
  font-weight: normal;
}

@-webkit-keyframes pulse-black {
	0% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(214, 90, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(214, 90, 0, 0);
	}
	
	100% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(214, 90, 0, 0);
	}
}

@keyframes pulse-black {
	0% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(214, 90, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(214, 90, 0, 0);
	}
	
	100% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(214, 90, 0, 0);
	}
}
/* ------------------------------------------ */
.input {
  display: block;
  height: 100%;
  width: 100%;
  border: 2px solid #BEBEBE;
  padding: 8px;
  resize: none;
  overflow-y: auto;
  font-size: calc(var(--sm) * 0.9);
  font-family: 'Consolas', monospace;
  line-height: 1.4em;
  background: whitesmoke;
}
.input:focus {
  outline: none;
  border: 2px solid var(--pink);
}
.sample-btn {
  position: fixed;
  bottom: calc(var(--btn-h) + var(--space-one) * 2.2);
  right: var(--space-one);
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  z-index: 1;
  cursor: pointer;
}
@-webkit-keyframes spinning-circle {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 150.6 100.4;
  }
  50% {
    stroke-dasharray: 1 250;
  }
  100% {
    stroke-dashoffset: 502;
    stroke-dasharray: 150.6 100.4;
  }
}
@keyframes spinning-circle {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 150.6 100.4;
  }
  50% {
    stroke-dasharray: 1 250;
  }
  100% {
    stroke-dashoffset: 502;
    stroke-dasharray: 150.6 100.4;
  }
}
.loader {
  height: calc(100% - var(--hdr-h) * 2);
}
.spinner, .err-msg {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 3em;
}
.spinner-circle {
  -webkit-animation: 2s linear spinning-circle infinite;
          animation: 2s linear spinning-circle infinite;
}
/* --------------- StepThumb ---------------- */
.step-thumb {
  display: flex;
  align-items: center;
  height: 44px;
  font-family: var(--code-font);
  font-weight: bold;
  border-bottom: 1px dotted gray;
  cursor: pointer;
}
.step-thumb.deactive {
  pointer-events: none;
}
.step-thumb.deactive .step-thumb-arrow {
  pointer-events: none;
  opacity: 0.1;
}
.step-thumb-item {
  font-size: var(--sm);
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
}
.step-thumb-item:first-child {
  text-decoration: line-through;
  -webkit-text-decoration-color: crimson;
          text-decoration-color: crimson;
}
.step-thumb-item:nth-child(2) {
  padding-left: 0.6em;
}
.step-thumb-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ------------------------------------------ */
.btn-big.proceed-btn {
  float: right;
  width: calc(100% - var(--btn-h) - var(--space-one));
}
.btn-big.delete-btn {
  float: left;
  width: var(--btn-h);
}
.btn-big.delete-btn:hover {
  background-color: crimson;
  border: 3px solid rgb(167, 21, 50);
}
.body.step {
  display: flex;
  flex-direction: column;
}
/* ------------------------------------------ */
.step-directions {
  margin-bottom: calc(var(--space-one) * 2);
  display: flex;
  flex-direction: column;
  font-size: var(--lg);
}
.step-item:nth-child(1) {
  margin: 0 auto var(--space-one);
  font-weight: bold;
  font-family: var(--code-font);
}
/* ------------------------------------------ */
.step-recorder {
  display: flex;
  justify-content: center;
  margin-bottom: calc(1.4 * var(--space-one));
}
.recorder-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  --ht: calc(var(--btn-h) * 1.2);
  width: var(--ht);
  height: var(--ht);
  border-radius: calc(var(--ht) * 0.5);
  border: 2px solid var(--dark);
  cursor: pointer;
}
/* ------------------------------------------ */
.record-thumb {
  display: flex;
  align-items: center;
}
.audio-player {
  width: calc(100% - 60px);
  width: 100%;
}
.audio-player:focus {
  outline: none;
}
.audio-player::-webkit-media-controls-panel {
  background-color: var(--light);
}
.record-thumb-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
}
.no-records {
  font-size: var(--sm);
  margin: auto;
}
/* -- "100%" important for transitions -- */
.menu-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--dark);
  color: var(--light);
  overflow: hidden;
  z-index: 3;
}
.menu {
  width: 100%;
  height: 100%;
}
/* ------------- MENU ITEMS ------------- */
.menu-item {
  display: flex;
  height: calc(var(--hdr-h) * 0.9);
  align-items: center;
  padding: var(--space-one);
  font-size: var(--md);
  cursor: pointer;
}
.menu-item h3 {
  font-weight: 300;
}
.menu-item.title {
  height: var(--hdr-h);
}
.menu-item.link {
  height: calc(var(--hdr-h) * 0.8);
}
.menu-item.link a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* ------------- MENU ICONS ------------- */
.menu-item .icon-left {
  display: flex;
}
.menu-item .icon-right {
  display: flex;
  margin-left: auto;
}
.menu-item .icon-left svg,
.menu-item .icon-right svg {
  stroke-width: 1px;
  width: 28px;
  height: 28px;
}
.menu-item .icon-left svg {
  margin-right: 20px;
}
.menu-item.link .icon-left svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}
/* ------------- MENU CONTENT ------------- */
.menu-content {
  padding: 0 var(--space-one);
  height: calc(100% - var(--hdr-h));
  overflow: auto;
  font-family: var(--serif-font);
  font-size: calc(var(--md) * 1.2);
  font-weight: 300;
}
.menu-content p {
  line-height: 1.7em;
  margin-bottom: calc(var(--space-one) * 1.6);
}
.menu-content .quote {
  font-size: calc(var(--sm) * 1.2);
  padding: 10px 15px;
  background-color: #3a3a3a;
  border-left: 5px solid #555555;
}
/* ------------- CSSTransition classes ------------- */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}
.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
.swiper-slide {
  left: 18%;
  top: calc(var(--space-one) * 0.5);
  list-style: none;
}
.swiper-slide:last-child .slide-txt {
  display: none;
}
.slide-img {
  position: relative;
  width: 100%;
}
.slide-txt {
  font-size: (var(--md));
  font-weight: 300;
  line-height: 1.4em;
  padding: 10px 0;
}
.swiper-pagination.swiper-pagination-progressbar {
  background: none;
  height: 2px;
}
.swiper-pagination-progressbar
.swiper-pagination-progressbar-fill {
  background: var(--pink);
}
.swiper-container-android .swiper-slide {
  transform: translateZ(10);
}
:root {
  --main-font: 'Manrope', 'Trebuchet MS', 'Helvetica', sans-serif;
  --code-font: 'Lekton', 'Consolas', 'Menlo', monospace;
  --serif-font: 'Bitter', serif;
  --pink: #c068a4;
  --pink-dark: #a74387;
  --light: #f3f1f1;
  --dark: #221f1f;
  --speed: 500ms;
  --space-one: 15px;
  --hdr-h: 60px;
  --btn-h: 50px;
  --lg: 1.3rem;
  --md: 1.0rem;
  --sm: 0.85rem;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  --dark-gray: #1f1f1f;
  --light-gray: #353535;
  font-family: 'Manrope', 'Trebuchet MS', 'Helvetica', sans-serif;
  font-family: var(--main-font);
  background-image: linear-gradient(
    135deg, var(--light-gray) 7.14%,
    var(--dark-gray) 7.14%,
    var(--dark-gray) 50%, 
    var(--light-gray) 50%,
    var(--light-gray) 57.14%,
    var(--dark-gray) 57.14%,
    var(--dark-gray) 100%);
  background-size: 10px 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit;
  text-decoration: none;
}
a:link, a:visited, a:active {
  text-decoration: none;
}
a:focus, button:focus {
  outline: 1px solid #cacaca;
}
button[disabled] {
  pointer-events: none;
  color: #f3f1f1;
  color: var(--light);
  background-color: #cacaca;
  border: 0;
}
button[disabled] > svg {
  fill:  #acacac
}
/* ------------------------------------------ */
.app-container {
  background-color: #f3f1f1;
  background-color: var(--light);
  /* height from App.js */
}
.main {
  height: calc(100% - 60px);
  height: calc(100% - var(--hdr-h));
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.body {
  height: calc(100% - 50px - 15px * 2.2);
  height: calc(100% - var(--btn-h) - var(--space-one) * 2.2);
  overflow-y: auto;
  margin-bottom: 15px;
  margin-bottom: var(--space-one);
  padding: 0 15px;
  padding: 0 var(--space-one);
}
.buttons {
  height: 50px;
  height: var(--btn-h);
  padding: 0 15px;
  padding: 0 var(--space-one);
}
/* ------------------------------------------ */
.btn-big {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: 100%;
  height: 50px;
  height: var(--btn-h);
  /* border-radius: calc(var(--btn-h) * 0.5); */
  font-family: 'Manrope', 'Trebuchet MS', 'Helvetica', sans-serif;
  font-family: var(--main-font);
  font-size: 1.3rem;
  font-size: var(--lg);
  background-color: #221f1f;
  background-color: var(--dark);
  color: #f3f1f1;
  color: var(--light);
}
.btn-big:hover {
  background-color: #c068a4;
  background-color: var(--pink);
  border: 3px solid #a74387;
  border: 3px solid var(--pink-dark);
}
/* ===================== For Desktop =================== */
@media (min-width: 620px) {
  :root {
    --space-one: 20px;
    --hdr-h: 90px;
    --btn-h: 70px;
    --lg: 1.6rem;
    --md: 1.2rem;
    --sm: 1.1rem;
  }
  .app-container {
    position: relative;
    width: 520px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: content-box;
    border: 5px solid #f3f1f1;
    border: 5px solid var(--light);
    outline: 2px solid black;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #b3b3b3;
    border: 2px solid transparent;
  }
}


.body.audio > *{
  margin-bottom: calc(var(--space-one) * 2);
}

.audio-title {
  font-size: var(--lg);
  font-family: var(--code-font);
  text-align: center;
}

.audio-description {
  font-size: var(--md);
}
