/* ------------------------------------------ */
.input {
  display: block;
  height: 100%;
  width: 100%;
  border: 2px solid #BEBEBE;
  padding: 8px;
  resize: none;
  overflow-y: auto;
  font-size: calc(var(--sm) * 0.9);
  font-family: 'Consolas', monospace;
  line-height: 1.4em;
  background: whitesmoke;
}
.input:focus {
  outline: none;
  border: 2px solid var(--pink);
}
.sample-btn {
  position: fixed;
  bottom: calc(var(--btn-h) + var(--space-one) * 2.2);
  right: var(--space-one);
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  z-index: 1;
  cursor: pointer;
}