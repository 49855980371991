.swiper-slide {
  left: 18%;
  top: calc(var(--space-one) * 0.5);
  list-style: none;
}
.swiper-slide:last-child .slide-txt {
  display: none;
}
.slide-img {
  position: relative;
  width: 100%;
}
.slide-txt {
  font-size: (var(--md));
  font-weight: 300;
  line-height: 1.4em;
  padding: 10px 0;
}
.swiper-pagination.swiper-pagination-progressbar {
  background: none;
  height: 2px;
}
.swiper-pagination-progressbar
.swiper-pagination-progressbar-fill {
  background: var(--pink);
}
.swiper-container-android .swiper-slide {
  transform: translateZ(10);
}