.body.audio > *{
  margin-bottom: calc(var(--space-one) * 2);
}

.audio-title {
  font-size: var(--lg);
  font-family: var(--code-font);
  text-align: center;
}

.audio-description {
  font-size: var(--md);
}