/* -- "100%" important for transitions -- */
.menu-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--dark);
  color: var(--light);
  overflow: hidden;
  z-index: 3;
}
.menu {
  width: 100%;
  height: 100%;
}
/* ------------- MENU ITEMS ------------- */
.menu-item {
  display: flex;
  height: calc(var(--hdr-h) * 0.9);
  align-items: center;
  padding: var(--space-one);
  font-size: var(--md);
  cursor: pointer;
}
.menu-item h3 {
  font-weight: 300;
}
.menu-item.title {
  height: var(--hdr-h);
}
.menu-item.link {
  height: calc(var(--hdr-h) * 0.8);
}
.menu-item.link a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* ------------- MENU ICONS ------------- */
.menu-item .icon-left {
  display: flex;
}
.menu-item .icon-right {
  display: flex;
  margin-left: auto;
}
.menu-item .icon-left svg,
.menu-item .icon-right svg {
  stroke-width: 1px;
  width: 28px;
  height: 28px;
}
.menu-item .icon-left svg {
  margin-right: 20px;
}
.menu-item.link .icon-left svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}
/* ------------- MENU CONTENT ------------- */
.menu-content {
  padding: 0 var(--space-one);
  height: calc(100% - var(--hdr-h));
  overflow: auto;
  font-family: var(--serif-font);
  font-size: calc(var(--md) * 1.2);
  font-weight: 300;
}
.menu-content p {
  line-height: 1.7em;
  margin-bottom: calc(var(--space-one) * 1.6);
}
.menu-content .quote {
  font-size: calc(var(--sm) * 1.2);
  padding: 10px 15px;
  background-color: #3a3a3a;
  border-left: 5px solid #555555;
}
/* ------------- CSSTransition classes ------------- */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}
.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}