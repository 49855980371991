/* --------------- StepThumb ---------------- */
.step-thumb {
  display: flex;
  align-items: center;
  height: 44px;
  font-family: var(--code-font);
  font-weight: bold;
  border-bottom: 1px dotted gray;
  cursor: pointer;
}
.step-thumb.deactive {
  pointer-events: none;
}
.step-thumb.deactive .step-thumb-arrow {
  pointer-events: none;
  opacity: 0.1;
}
.step-thumb-item {
  font-size: var(--sm);
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
}
.step-thumb-item:first-child {
  text-decoration: line-through;
  text-decoration-color: crimson;
}
.step-thumb-item:nth-child(2) {
  padding-left: 0.6em;
}
.step-thumb-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ------------------------------------------ */
.btn-big.proceed-btn {
  float: right;
  width: calc(100% - var(--btn-h) - var(--space-one));
}
.btn-big.delete-btn {
  float: left;
  width: var(--btn-h);
}
.btn-big.delete-btn:hover {
  background-color: crimson;
  border: 3px solid rgb(167, 21, 50);
}