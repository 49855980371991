@import url('https://fonts.googleapis.com/css2?family=Lekton:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,300;0,400;0,600;1,300;1,600&display=swap');

:root {
  --main-font: 'Manrope', 'Trebuchet MS', 'Helvetica', sans-serif;
  --code-font: 'Lekton', 'Consolas', 'Menlo', monospace;
  --serif-font: 'Bitter', serif;
  --pink: #c068a4;
  --pink-dark: #a74387;
  --light: #f3f1f1;
  --dark: #221f1f;
  --speed: 500ms;
  --space-one: 15px;
  --hdr-h: 60px;
  --btn-h: 50px;
  --lg: 1.3rem;
  --md: 1.0rem;
  --sm: 0.85rem;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  --dark-gray: #1f1f1f;
  --light-gray: #353535;
  font-family: var(--main-font);
  background-image: linear-gradient(
    135deg, var(--light-gray) 7.14%,
    var(--dark-gray) 7.14%,
    var(--dark-gray) 50%, 
    var(--light-gray) 50%,
    var(--light-gray) 57.14%,
    var(--dark-gray) 57.14%,
    var(--dark-gray) 100%);
  background-size: 10px 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit;
  text-decoration: none;
}
a:link, a:visited, a:active {
  text-decoration: none;
}
a:focus, button:focus {
  outline: 1px solid #cacaca;
}
button[disabled] {
  pointer-events: none;
  color: var(--light);
  background-color: #cacaca;
  border: 0;
}
button[disabled] > svg {
  fill:  #acacac
}
/* ------------------------------------------ */
.app-container {
  background-color: var(--light);
  /* height from App.js */
}
.main {
  height: calc(100% - var(--hdr-h));
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.body {
  height: calc(100% - var(--btn-h) - var(--space-one) * 2.2);
  overflow-y: auto;
  margin-bottom: var(--space-one);
  padding: 0 var(--space-one);
}
.buttons {
  height: var(--btn-h);
  padding: 0 var(--space-one);
}
/* ------------------------------------------ */
.btn-big {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: 100%;
  height: var(--btn-h);
  /* border-radius: calc(var(--btn-h) * 0.5); */
  font-family: var(--main-font);
  font-size: var(--lg);
  background-color: var(--dark);
  color: var(--light);
}
.btn-big:hover {
  background-color: var(--pink);
  border: 3px solid var(--pink-dark);
}
/* ===================== For Desktop =================== */
@media (min-width: 620px) {
  :root {
    --space-one: 20px;
    --hdr-h: 90px;
    --btn-h: 70px;
    --lg: 1.6rem;
    --md: 1.2rem;
    --sm: 1.1rem;
  }
  .app-container {
    position: relative;
    width: 520px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: content-box;
    border: 5px solid var(--light);
    outline: 2px solid black;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #b3b3b3;
    border: 2px solid transparent;
  }
}

