/* --------------- DriftThumb ---------------- */
.drift-thumb {
  display: flex;
  align-items: center;
  background-color: var(--light);
  padding: calc(var(--space-one) * 0.5) var(--space-one);
  margin-bottom: calc(var(--space-one) * 1.2);
  border: 2px solid var(--dark);
  box-shadow: 4px 4px 0 var(--dark);
  cursor: pointer;
  background: whitesmoke;
}
.drift-thumb:last-child {
  margin-bottom: 6px;
}
.drift-thumb-list li {
  list-style: none;
  font-size: var(--md);
  line-height: 1.8em;
}
.drift-thumb-list span{
  color: crimson;
}
.drift-thumb-arrow {
  display: flex;
  align-items: center;
  margin-left: auto;
}
/* ------------------------------------------ */
.drift-empty {
  font-size: var(--md);
  position: relative;
  /* width: 100%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 2.4em;
}
/* ------------------------------------------ */
.audiowalk {
  background-color: rgb(214, 90, 0);
  cursor: pointer;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.1em;
	box-shadow: 0 0 0 0 rgba(214, 90, 0, 1);
	transform: scale(1);
	animation: pulse-black 2s infinite;
}
.audiowalk p:last-child {
  font-size: 0.8em;
  margin-top: 8px;
  font-weight: normal;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(214, 90, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(214, 90, 0, 0);
	}
	
	100% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(214, 90, 0, 0);
	}
}