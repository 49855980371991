.header {
  display: flex;
  align-items: center;
  height: var(--hdr-h);
  padding: 0 var(--space-one);
}
.header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
}
.header-btn.left {
  margin-right: auto;
}
.header-btn.right {
  margin-left: auto;
}
.header-btn.disabled {
  pointer-events: none;
}
.header-title {
  margin: auto;
  font-size: var(--md);
  font-weight: bold;
  font-family: var(--code-font);
}